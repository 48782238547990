import React, { createContext, useContext, useState } from 'react';

interface ILayoutContextData {
    title: string;
    user: any;
    showMenu: boolean;
    setTitle(title: string): void;
    setUser(user: any): void;
    setShowMenu(value: boolean): void;
}

const LayoutContext = createContext<ILayoutContextData>(
    {} as ILayoutContextData
);

const LayoutDefault: React.FC<any> = ({ children }) => {
    const [title, setTitle] = useState('');
    const [showMenu, setShowMenu] = useState(false);
    const [user, setUser] = useState(null as any);

    return (
        <LayoutContext.Provider
            value={{
                title,
                user,
                showMenu,
                setTitle,
                setUser,
                setShowMenu
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};

export const useLayout = (): ILayoutContextData => {
    const context = useContext(LayoutContext);

    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }

    return context;
};

export default LayoutDefault;
