import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import ModalAccount from '../../components/ModalAccount';

const CompanyEditPage: React.FC = () => {
    const { id } = useParams();

    const formRef = useRef<FormHandles>(null);

    const toast = useToast();

    const [company, setCompany] = useState(null as any);
    const [accounts, setAccounts] = useState<any[]>([]);

    const { isOpen, onOpen, onClose } = useDisclosure();

    // const handleDelete = useCallback((accountId: string) => {
    //     setAccounts(oldState => {
    //         const newState = oldState.filter(item => item.id !== accountId);

    //         return [...newState];
    //     });
    // }, []);

    const handleAddAccount = useCallback(data => {
        setAccounts(oldState => {
            const newState = oldState;

            newState.push(data);

            return [...newState];
        });

        onClose();
    }, []);

    const handleSubmit = useCallback(() => {
        const data = formRef.current?.getData();

        apiBackend('')
            .put(`/companies/${id}`, {
                ...data,
                accounts
            })
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    toast({
                        title: 'Empresa Atualizada',
                        description: '',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });

                    history.go(0);
                }

                if (status === HTTP_RESPONSE.STATUS.BAD_REQUEST) {
                    toast({
                        title: 'Atenção',
                        description: data.message,
                        status: 'warning',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });
    }, [id, accounts]);

    const handleLoadData = useCallback(() => {
        apiBackend('')
            .get(`/companies/${id}`)
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setCompany(data);
                    setAccounts(data.accounts);
                }
            });
    }, [id]);

    useEffect(() => {
        handleLoadData();
    }, [handleLoadData]);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href="/companies">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/companies/create">
                            Novo
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    flexDirection="column"
                    color="gray.900"
                    backgroundColor="white"
                    p="32px"
                >
                    <Text fontWeight="500" fontSize="16px" mb="13px">
                        Configurações Gerais
                    </Text>

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        flexDirection={['column', 'column', 'row']}
                    >
                        <Flex width={['48%']} flexDirection="column">
                            <Input
                                name="name"
                                label="Empresa"
                                isRequired
                                defaultValue={company?.name}
                            />
                            <Input
                                name="document"
                                label="Documento"
                                defaultValue={company?.document}
                            />
                        </Flex>
                    </Flex>

                    <Flex
                        flexDirection="column"
                        mb="48px"
                        border="1px solid"
                        borderColor="yellow.500"
                        backgroundColor="yellow.100"
                        px="16px"
                        py="8px"
                        color="gray.600"
                    >
                        <Text fontWeight="500" fontSize="20px" mb="8px">
                            Credenciais
                        </Text>

                        {accounts.map((account: any, index: number) => (
                            <Flex key={account.id}>
                                <Text mr="16px" fontWeight="600">{`Conta ${
                                    index + 1
                                }`}</Text>
                                <Text>{`Public ID: ${account.id}`}</Text>
                            </Flex>
                        ))}
                    </Flex>

                    <Flex alignItems="center" justifyContent="space-between">
                        <Text fontWeight="500" fontSize="16px" mb="13px">
                            Contas
                        </Text>

                        <Button
                            title="Adicionar Conta"
                            backgroundColor="blue.500"
                            color="white"
                            width="160px"
                            borderRadius="4px"
                            py="4px"
                            fontSize="14px"
                            onClick={() => {
                                onOpen();
                            }}
                            mb="16px"
                        />
                    </Flex>

                    <Flex
                        width="100%"
                        flexDirection="column"
                        overflow="auto"
                        borderRadius="8px"
                        border="1px solid"
                        borderColor="gray.100"
                    >
                        <Flex
                            width="auto"
                            minWidth="100%"
                            px="8px"
                            borderRadius="8px"
                            borderBottom="1px solid"
                            borderColor="gray.100"
                            py="4px"
                            fontWeight="500"
                            fontSize="14px"
                        >
                            <Flex width="5%" justifyContent="center">
                                <Text>#</Text>
                            </Flex>

                            <Flex width="30%" justifyContent="center">
                                <Text>Whatsapp</Text>
                            </Flex>

                            <Flex width="20%" justifyContent="center">
                                <Text>Máx. Notification [POST]</Text>
                            </Flex>

                            <Flex width="25%" justifyContent="center">
                                <Text>Máx. Protocol [POST]</Text>
                            </Flex>

                            <Flex width="20%" justifyContent="center">
                                <Text>Máx. Notif. Status [GET]</Text>
                            </Flex>
                        </Flex>

                        {accounts.map((account: any, index: number) => (
                            <Flex
                                key={account.id}
                                width="100%"
                                flexDirection="column"
                                fontSize="14px"
                                px="8px"
                            >
                                <Flex width="100%" py="8px">
                                    <Flex width="5%" justifyContent="center">
                                        <Text>{index + 1}</Text>
                                    </Flex>

                                    <Flex width="30%" justifyContent="center">
                                        <Text>{account.whatsapp}</Text>
                                    </Flex>

                                    <Flex width="20%" justifyContent="center">
                                        <Text>
                                            {account.limits
                                                .notification_post === '-1'
                                                ? 'ilimitado'
                                                : account.limits
                                                      .notification_post}
                                        </Text>
                                    </Flex>

                                    <Flex width="20%" justifyContent="center">
                                        <Text>
                                            {account.limits.protocol_post ===
                                            '-1'
                                                ? 'ilimitado'
                                                : account.limits.protocol_post}
                                        </Text>
                                    </Flex>

                                    <Flex width="25%" justifyContent="center">
                                        <Text>
                                            {account.limits.status_get === '-1'
                                                ? 'ilimitado'
                                                : account.limits.status_get}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>

                    <Flex width="100%" justifyContent="center" mt="24px">
                        <Button
                            title="Atualizar"
                            backgroundColor="blue.500"
                            color="white"
                            width="200px"
                            borderRadius="4px"
                            py="8px"
                            onClick={() => handleSubmit()}
                        />
                    </Flex>
                </Flex>
                <ModalAccount
                    isOpen={isOpen}
                    onClose={onClose}
                    addFunction={handleAddAccount}
                />
            </Flex>
        </Form>
    );
};

export default CompanyEditPage;
