import React, { useCallback, useRef, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Icon,
    Stack,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import ModalAccount from '../../components/ModalAccount';

const CompanyRegisterPage: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    const navigate = useNavigate();
    const toast = useToast();

    const [accounts, setAccounts] = useState<any[]>([]);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleDelete = useCallback((accountId: string) => {
        setAccounts(oldState => {
            const newState = oldState.filter(item => item.id !== accountId);

            return [...newState];
        });
    }, []);

    const handleAddAccount = useCallback(data => {
        setAccounts(oldState => {
            const newState = oldState;

            newState.push(data);

            return [...newState];
        });

        onClose();
    }, []);

    const handleSubmit = useCallback(() => {
        const data = formRef.current?.getData();

        apiBackend('')
            .post('/companies', {
                ...data,
                accounts
            })
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.CREATED) {
                    toast({
                        title: 'Empresa Registrada',
                        description: '',
                        status: 'success',
                        duration: 4000,
                        isClosable: true
                    });

                    navigate('/companies');
                }

                if (status === HTTP_RESPONSE.STATUS.BAD_REQUEST) {
                    toast({
                        title: 'Atenção',
                        description: data.message,
                        status: 'warning',
                        duration: 4000,
                        isClosable: true
                    });
                }
            });
    }, [accounts]);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href="/companies">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/companies/create">
                            Novo
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    flexDirection="column"
                    color="gray.900"
                    backgroundColor="white"
                    p="32px"
                >
                    <Text fontWeight="500" fontSize="16px" mb="13px">
                        Configurações Gerais
                    </Text>

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        flexDirection={['column', 'column', 'row']}
                    >
                        <Flex width={['48%']} flexDirection="column">
                            <Input name="name" label="Empresa" isRequired />
                            <Input name="document" label="Documento" />
                        </Flex>
                    </Flex>

                    <Flex alignItems="center" justifyContent="space-between">
                        <Text fontWeight="500" fontSize="16px" mb="13px">
                            Contas
                        </Text>

                        <Button
                            title="Adicionar Conta"
                            backgroundColor="blue.500"
                            color="white"
                            width="160px"
                            borderRadius="4px"
                            py="4px"
                            fontSize="14px"
                            onClick={() => {
                                onOpen();
                            }}
                            mb="16px"
                        />
                    </Flex>

                    <Flex
                        width="100%"
                        flexDirection="column"
                        overflow="auto"
                        borderRadius="8px"
                        border="1px solid"
                        borderColor="gray.100"
                    >
                        <Flex
                            width="auto"
                            minWidth="100%"
                            px="8px"
                            borderRadius="8px"
                            borderBottom="1px solid"
                            borderColor="gray.100"
                            py="4px"
                            fontWeight="500"
                            fontSize="14px"
                        >
                            <Flex width="5%" justifyContent="center">
                                <Text>#</Text>
                            </Flex>

                            <Flex width="30%" justifyContent="center">
                                <Text>Whatsapp</Text>
                            </Flex>

                            <Flex width="20%" justifyContent="center">
                                <Text>Máx. Notification [POST]</Text>
                            </Flex>

                            <Flex width="20%" justifyContent="center">
                                <Text>Máx. Protocol [POST]</Text>
                            </Flex>

                            <Flex width="20%" justifyContent="center">
                                <Text>Máx. Notif. Status [GET]</Text>
                            </Flex>

                            <Flex width="5%" justifyContent="center">
                                <Text>Ação</Text>
                            </Flex>
                        </Flex>

                        {accounts.map((account: any, index: number) => (
                            <Flex
                                key={account.id}
                                width="100%"
                                flexDirection="column"
                                fontSize="14px"
                                px="8px"
                            >
                                <Flex width="100%" py="8px">
                                    <Flex width="5%" justifyContent="center">
                                        <Text>{index + 1}</Text>
                                    </Flex>

                                    <Flex width="30%" justifyContent="center">
                                        <Text>{account.whatsapp}</Text>
                                    </Flex>

                                    <Flex width="20%" justifyContent="center">
                                        <Text>
                                            {account.limits.notification_post}
                                        </Text>
                                    </Flex>

                                    <Flex width="20%" justifyContent="center">
                                        <Text>
                                            {account.limits.protocol_post}
                                        </Text>
                                    </Flex>

                                    <Flex width="20%" justifyContent="center">
                                        <Text>{account.limits.status_get}</Text>
                                    </Flex>

                                    <Flex width="5%" justifyContent="center">
                                        <Stack direction="row">
                                            <Flex
                                                width="24px"
                                                height="24px"
                                                backgroundColor="red.500"
                                                color="white"
                                                justifyContent="center"
                                                alignItems="center"
                                                title="Excluir"
                                                cursor="pointer"
                                                borderRadius="2px"
                                                onClick={() =>
                                                    handleDelete(account.id)
                                                }
                                            >
                                                <Icon
                                                    as={FiTrash2}
                                                    fontSize="18px"
                                                />
                                            </Flex>
                                        </Stack>
                                    </Flex>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>

                    <Flex width="100%" justifyContent="center" mt="24px">
                        <Button
                            title="Salvar"
                            backgroundColor="blue.500"
                            color="white"
                            width="200px"
                            borderRadius="4px"
                            py="8px"
                            onClick={() => handleSubmit()}
                        />
                    </Flex>
                </Flex>
                <ModalAccount
                    isOpen={isOpen}
                    onClose={onClose}
                    addFunction={handleAddAccount}
                />
            </Flex>
        </Form>
    );
};

export default CompanyRegisterPage;
