import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Heading, Button, useToast, Text } from '@chakra-ui/react';
import { Form } from '@unform/web';

import Input from '../../../shared/components/Input';
import { useLayout } from '../../../shared/hooks/layout';

type DataSubmit = {
    email: string;
    password: string;
};

const LoginPage: React.FC = () => {
    const navigate = useNavigate();

    const { setUser } = useLayout();
    const toast = useToast();

    const handleSubmitLogin = useCallback(
        async (dataSubmit: DataSubmit) => {
            const { email, password } = dataSubmit;

            if (
                email === process.env.REACT_APP_API_LOGIN &&
                password === process.env.REACT_APP_API_PASSWORD
            ) {
                localStorage.setItem(
                    '@admin:api_sinc',
                    JSON.stringify({
                        email,
                        password
                    })
                );

                setUser({
                    email,
                    password
                });

                navigate('/dashboard');
            } else {
                toast({
                    title: 'Login Invalido',
                    description: '',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                });
            }
        },
        [navigate, LoginPage]
    );

    useEffect(() => {
        const userData = localStorage.getItem('@admin:api_sinc');

        if (userData) {
            const { email, password } = JSON.parse(userData);
            setUser({
                email,
                password
            });

            navigate('/dashboard');
        }
    }, []);

    return (
        <Flex width="100%" height="100vh">
            <Flex
                width="50%"
                justifyContent="center"
                alignItems="center"
                backgroundColor="gray.900"
                height="100%"
            >
                <Flex alignItems="center">
                    <Text color="white" fontWeight="600" fontSize="40px">
                        Sinc Dashboard
                    </Text>
                </Flex>
            </Flex>
            <Flex
                width="50%"
                justifyContent="center"
                alignItems="center"
                height="100%"
            >
                <Form
                    onSubmit={handleSubmitLogin}
                    style={{
                        width: '100%'
                    }}
                >
                    <Flex
                        width="100%"
                        flexDirection="column"
                        p="98px"
                        fontWeight="500"
                        color="gray.900"
                    >
                        <Heading fontSize="24px" textAlign="center">
                            Faça seu login
                        </Heading>

                        <Input
                            name="email"
                            size="md"
                            placeholder="Seu email"
                            mt="24px"
                        />
                        <Input
                            name="password"
                            type="password"
                            size="md"
                            placeholder="Sua senha"
                        />

                        <Flex justifyContent="space-between">
                            <Flex width="50%">
                                <Button
                                    type="submit"
                                    width="100%"
                                    maxWidth="120px"
                                    fontSize="16px"
                                    py="8px"
                                    color="white"
                                    backgroundColor="gray.900"
                                    _hover={{
                                        backgroundColor: 'gray.900'
                                    }}
                                >
                                    Entrar
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Form>
            </Flex>
        </Flex>
    );
};

export default LoginPage;
