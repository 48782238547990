import React, { useCallback, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import {
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Flex,
    Button,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';

interface IProps {
    isOpen: any;
    onClose: any;
    addFunction: any;
}

const ModalAccount: React.FC<IProps> = ({ isOpen, onClose, addFunction }) => {
    const formRef = useRef<FormHandles>(null as any);
    const toast = useToast();

    const handleSubmit = useCallback(() => {
        const data = formRef.current?.getData();

        const { whatsapp, api_whatsapp_client, api_whatsapp_secret } = data;

        if (!whatsapp || !api_whatsapp_client || !api_whatsapp_secret) {
            toast({
                title: 'Atenção',
                description: 'Preencha os campos obrigatórios',
                status: 'warning',
                duration: 4000,
                isClosable: true
            });
        } else {
            addFunction({
                id: uuid(),
                ...data,
                limits: {
                    notification_post: data.notification_post,
                    protocol_post: data.protocol_post,
                    status_get: data.status_get
                }
            });

            formRef.current.reset();
        }
    }, []);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody pb="8px">
                        <Form ref={formRef}>
                            <Flex width="100%" flexDirection="column">
                                <Text fontSize="16px" fontWeight="500">
                                    Nova Conta
                                </Text>
                            </Flex>

                            <Flex
                                width={'100'}
                                flexDirection="column"
                                mt="24px"
                            >
                                <Input
                                    name="whatsapp"
                                    label="Número Whatsapp"
                                    mask="(99) 99999-9999"
                                    isRequired
                                />

                                <Input
                                    name="api_whatsapp_client"
                                    label="API SINC ID"
                                    isRequired
                                />

                                <Input
                                    name="api_whatsapp_secret"
                                    label="API SINC SECRET"
                                    isRequired
                                />

                                <Text fontSize="16px" fontWeight="500">
                                    Limites Diário
                                </Text>

                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                >
                                    <Input
                                        width="48%"
                                        name="notification_post"
                                        type="number"
                                        label="/notification [POST]"
                                        defaultValue={-1}
                                    />

                                    <Input
                                        width="48%"
                                        name="protocol_post"
                                        type="number"
                                        label="/protocol [POST]"
                                        defaultValue={-1}
                                    />

                                    <Input
                                        width="48%"
                                        name="status_get"
                                        type="number"
                                        label="/notification/status [GET]"
                                        defaultValue={-1}
                                    />
                                </Flex>

                                <Flex width="100%" fontSize="12px">
                                    <Text>
                                        Para request ilimitado, utilize o valor:
                                        -1
                                    </Text>
                                </Flex>
                            </Flex>

                            <Flex
                                width="100%"
                                flexDirection="row"
                                justifyContent="space-between"
                                mt="16px"
                                mb="8px"
                                fontWeight="500"
                            >
                                <Button
                                    width="48%"
                                    backgroundColor="blue.500"
                                    onClick={() => handleSubmit()}
                                    color="white"
                                    _hover={{
                                        backgroundColor: 'blue.500'
                                    }}
                                >
                                    Cadastrar
                                </Button>
                                <Button
                                    width="48%"
                                    backgroundColor="red.500"
                                    color="white"
                                    onClick={() => onClose()}
                                    _hover={{
                                        backgroundColor: 'red.500'
                                    }}
                                >
                                    Cancelar
                                </Button>
                            </Flex>
                        </Form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ModalAccount;
