export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const HTTP_RESPONSE = {
    STATUS: {
        SUCCESS: 200,
        CREATED: 201,
        BAD_REQUEST: 400,
        NOT_AUTORIZED: 401,
        INTERNAL_ERROR: 500
    }
};
