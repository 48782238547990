import axios from 'axios';

const apiBackend = (token: string) => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        validateStatus: (status: number) => {
            return status >= 200 && status <= 500;
        },
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export default apiBackend;
