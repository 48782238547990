import React, { useCallback } from 'react';
import { BiCategory, BiNotepad } from 'react-icons/bi';
import { FiLogOut, FiX } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

import { Flex, Icon, Text, Stack } from '@chakra-ui/react';

import { useLayout } from '../../hooks/layout';

const SideMenu: React.FC = () => {
    const { setUser, setShowMenu } = useLayout();

    const navigate = useNavigate();

    const location = useLocation();

    const MENU = [
        {
            title: 'Dashboard',
            icon: BiCategory,
            link: '/dashboard',
            submenus: []
        },
        {
            title: 'Empresas',
            icon: BiNotepad,
            link: '/companies',
            submenus: []
        }
    ];

    const handleClickMenu = useCallback((item: any) => {
        navigate(item.link);
        setShowMenu(false);
    }, []);

    const handleLogout = useCallback(async () => {
        setShowMenu(false);
        setUser(null);
        localStorage.clear();
        navigate('/login');
    }, []);

    return (
        <Flex
            width="192px"
            height="100vh"
            flexDirection="column"
            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
            backgroundColor="gray.900"
            position="fixed"
        >
            <Flex
                width="100%"
                justifyContent="center"
                height="80px"
                backgroundColor="black"
                position="relative"
            >
                <Flex alignItems="center">
                    <Text color="white" fontWeight="600" fontSize="20px">
                        Sinc Dashboard
                    </Text>
                </Flex>

                <Flex
                    width="24px"
                    height="24px"
                    backgroundColor="blue.500"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    top="0"
                    right="0"
                    onClick={() => setShowMenu(false)}
                    display={['flex', 'flex', 'flex', 'none']}
                >
                    <Icon as={FiX} color="white" />
                </Flex>
            </Flex>

            <Stack pl="16px" mt="auto">
                {MENU.map(item => (
                    <Flex
                        key={item.title}
                        height="32px"
                        alignItems="center"
                        onClick={() => handleClickMenu(item)}
                        cursor="pointer"
                        backgroundColor={
                            location.pathname === item.link
                                ? 'gray.500'
                                : 'gray.900'
                        }
                        pl="16px"
                    >
                        <Icon as={item.icon} color="yellow.500" mr="8px" />
                        <Text color="gray.100" fontSize="14px">
                            {item.title}
                        </Text>
                    </Flex>
                ))}

                <Flex
                    height="32px"
                    alignItems="center"
                    onClick={() => handleLogout()}
                    cursor="pointer"
                    pl="16px"
                >
                    <Icon as={FiLogOut} color="yellow.500" mr="8px" />
                    <Text color="gray.100" fontSize="14px">
                        Sair
                    </Text>
                </Flex>
            </Stack>

            <Flex
                width="100%"
                mt="auto"
                justifyContent="center"
                py="8px"
                cursor="pointer"
            ></Flex>
        </Flex>
    );
};

export default SideMenu;
