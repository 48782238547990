export default class TextUtils {
    static removeCharacters(value: string) {
        const newValue = value.replace(/[^a-zA-Z0-9]/g, '');
        return newValue;
    }

    static convertStatusColor(value: string) {
        switch (value) {
            case '200':
                return 'blue.500';
            case '400':
                return 'yellow.500';
            default:
                return 'red.500';
        }
    }
}
