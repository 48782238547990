import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Chart from 'react-google-charts';
import { BiRefresh } from 'react-icons/bi';

import { Badge, Checkbox, Flex, Icon, Text } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../apis/index';
import Form from '../components/Form';
import Input from '../components/Input';
import RequestView from '../components/RequestView';
import Select from '../components/Select';
import { HTTP_RESPONSE } from '../constants';
import TextUtils from '../utils/TextUtils';

type RequestDTO = {
    id: string;
    endpoint: string;
    method: string;
    status: string;
    body: string;
    data_contact_id?: string;
    data_notification_id?: string;
    data_protocol_id?: string;
    session_id: string;
    created_at: string;

    session: {
        account: {
            whatsapp: string;
            company: {
                name: string;
            };
        };
    };
};

const Dashboard: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    const [requests, setRequests] = useState<RequestDTO[]>([]);

    const [companies, setCompanies] = useState([]);
    const [accounts, setAccounts] = useState([]);

    const [company, setCompany] = useState('');
    const [account, setAccount] = useState('');
    const [endpoint, setEndpoint] = useState('');
    const [successCheck, setSuccessCheck] = useState(false);
    const [badRequestCheck, setBadRequestCheck] = useState(false);
    const [errorCheck, setErrorCheck] = useState(false);

    const [request, setRequest] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const [data, setData] = useState({
        http200Length: 0,
        http400Length: 0,
        http500Length: 0
    });

    const handleSearch = useCallback(() => {
        const data = formRef.current?.getData();

        const filter1 = company ? `companyId=${company}` : '';
        const filter2 = account ? `accountId=${account}` : '';
        const filter3 = endpoint ? `endpoint=${endpoint}` : '';
        const filter4 = successCheck ? 'status[]=200' : '';
        const filter5 = badRequestCheck ? 'status[]=400' : '';
        const filter6 = errorCheck ? 'status[]=500' : '';
        const filter7 = data?.start_date ? `startDate=${data.start_date}` : '';
        const filter8 = data?.end_date ? `endDate=${data.end_date}` : '';

        apiBackend('')
            .get(
                `/requests?${filter1}&${filter2}&${filter3}&${filter4}&${filter5}&${filter6}&${filter7}&${filter8}`
            )
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setRequests(data);

                    setData({
                        http200Length: data.filter(
                            item => item.status === '200'
                        ).length,
                        http400Length: data.filter(
                            item => item.status === '400'
                        ).length,
                        http500Length: data.filter(
                            item =>
                                item.status !== '200' && item.status !== '400'
                        ).length
                    });
                }
            });
    }, [
        formRef,
        company,
        account,
        endpoint,
        successCheck,
        badRequestCheck,
        errorCheck
    ]);

    const handleClickRequest = useCallback((data?: any) => {
        if (data) {
            setRequest(data);
            setIsOpen(true);
        }
    }, []);

    const handleLoadData = useCallback(() => {
        apiBackend('')
            .get('/companies')
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setCompanies(data);
                }
            });

        apiBackend('')
            .get('/requests')
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setRequests(data);

                    setData({
                        http200Length: data.filter(
                            item => item.status === '200'
                        ).length,
                        http400Length: data.filter(
                            item => item.status === '400'
                        ).length,
                        http500Length: data.filter(
                            item =>
                                item.status !== '200' && item.status !== '400'
                        ).length
                    });
                }
            });
    }, []);

    useEffect(() => {
        handleLoadData();
    }, [handleLoadData]);

    return (
        <Form ref={formRef}>
            <Flex
                width="100%"
                height="calc(100vh - 104px)"
                backgroundColor="white"
                flexDirection="row"
                p="16px"
                justifyContent="space-between"
            >
                <Flex width="18%" height="100%" flexDirection="column">
                    <Select
                        name="company"
                        label="Empresa"
                        onChange={e => {
                            setCompany(e.currentTarget.value);

                            const selectedCompany: any = companies.find(
                                (item: any) => item.id === e.currentTarget.value
                            );

                            if (selectedCompany) {
                                setAccounts(selectedCompany.accounts);
                            } else {
                                setAccounts([]);
                            }
                        }}
                    >
                        <option value="">Todos</option>
                        {companies.map((item: any) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Select>

                    <Select
                        name="account"
                        label="Conta"
                        onChange={e => setAccount(e.currentTarget.value)}
                    >
                        <option value="">Todos</option>
                        {accounts.map((item: any) => (
                            <option key={item.id} value={item.id}>
                                {item.whatsapp}
                            </option>
                        ))}
                    </Select>

                    <Select
                        name="company"
                        label="Endpoint"
                        onChange={e => setEndpoint(e.currentTarget.value)}
                    >
                        <option value="">Todos</option>
                        <option value="contact">/contact</option>
                        <option value="notification">/notification</option>
                        <option value="notification/contact">
                            /notification/contact
                        </option>
                        <option value="notification/direct">
                            /notification/direct
                        </option>
                        <option value="protocol">/protocol</option>
                    </Select>

                    <Text fontSize={'14px'} fontWeight="600" mb="4px">
                        Status
                    </Text>

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        mb="8px"
                        fontSize="14px"
                    >
                        <Flex alignItems="center">
                            <Checkbox
                                size="lg"
                                isChecked={successCheck}
                                onChange={() => setSuccessCheck(!successCheck)}
                            ></Checkbox>
                            <Flex
                                ml="8px"
                                borderRadius="10px"
                                width="6px"
                                height="20px"
                                backgroundColor="blue.500"
                            ></Flex>
                            <Text ml="8px" fontWeight="500">
                                Success
                            </Text>
                        </Flex>

                        <Text ml="8px">{data.http200Length}</Text>
                    </Flex>

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        mb="8px"
                        fontSize="14px"
                    >
                        <Flex alignItems="center">
                            <Checkbox
                                size="lg"
                                isChecked={badRequestCheck}
                                onChange={() =>
                                    setBadRequestCheck(!badRequestCheck)
                                }
                            ></Checkbox>
                            <Flex
                                ml="8px"
                                borderRadius="10px"
                                width="6px"
                                height="20px"
                                backgroundColor="yellow.500"
                            ></Flex>
                            <Text ml="8px" fontWeight="500">
                                Bad Request
                            </Text>
                        </Flex>

                        <Text ml="8px">{data.http400Length}</Text>
                    </Flex>

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        mb="8px"
                        fontSize="14px"
                    >
                        <Flex alignItems="center">
                            <Checkbox
                                size="lg"
                                isChecked={errorCheck}
                                onChange={() => setErrorCheck(!errorCheck)}
                            ></Checkbox>
                            <Flex
                                ml="8px"
                                borderRadius="10px"
                                width="6px"
                                height="20px"
                                backgroundColor="red.500"
                            ></Flex>
                            <Text ml="8px" fontWeight="500">
                                Error
                            </Text>
                        </Flex>

                        <Text ml="8px">{data.http500Length}</Text>
                    </Flex>

                    <Flex
                        mt="16px"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        fontSize="14px"
                    >
                        <Input
                            name="start_date"
                            type="date"
                            fontWeight="600"
                            label="De"
                            mb="4px"
                        />
                    </Flex>

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        fontSize="14px"
                    >
                        <Input
                            name="end_date"
                            type="date"
                            fontWeight="600"
                            label="Até"
                        />
                    </Flex>

                    <Flex width="100%" mt="auto" px="16px">
                        <Flex
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                            backgroundColor="blue.500"
                            borderRadius="2px"
                            py="4px"
                            cursor="pointer"
                            onClick={() => handleSearch()}
                        >
                            <Text
                                fontSize="20px"
                                color="white"
                                fontWeight="500"
                            >
                                Atualizar
                            </Text>

                            <Icon
                                ml="16px"
                                as={BiRefresh}
                                color="white"
                                fontSize="28px"
                            />
                        </Flex>
                    </Flex>
                </Flex>
                <Flex width="80%" flexDirection="column">
                    <Flex
                        borderRadius="8px"
                        border="1px solid"
                        borderColor="gray.100"
                        p="16px"
                    >
                        <Chart
                            chartType="Bar"
                            width="100%"
                            height="200px"
                            data={[
                                ['', 'Success', 'Bad Request', 'Error'],
                                [
                                    'Todos',
                                    data.http200Length,
                                    data.http400Length,
                                    data.http500Length
                                ]
                            ]}
                            options={{
                                colors: ['#4285f4', '#f4b400', '#db4437']
                            }}
                        />
                    </Flex>

                    <Flex
                        mt="16px"
                        width="100%"
                        height="100%"
                        flexDirection="column"
                        borderRadius="8px"
                        border="1px solid"
                        borderColor="gray.100"
                        overflow="auto"
                    >
                        <Flex
                            width="100%"
                            fontWeight="500"
                            justifyContent="space-between"
                            borderBottom="1px solid"
                            borderColor="gray.100"
                            alignItems="center"
                            py="4px"
                        >
                            <Text width="20%" textAlign="center">
                                Time
                            </Text>
                            <Text width="20%" textAlign="center">
                                Empresa
                            </Text>
                            <Text width="20%" textAlign="center">
                                Conta
                            </Text>
                            <Text width="20%" textAlign="center">
                                Tipo
                            </Text>
                            <Text width="20%" textAlign="center">
                                Endpoint
                            </Text>
                            <Text width="20%" textAlign="center">
                                Status
                            </Text>
                        </Flex>

                        {requests.map(request => (
                            <Flex
                                key={request.id}
                                width="100%"
                                justifyContent="space-between"
                                borderBottom="1px solid"
                                borderColor="gray.100"
                                alignItems="center"
                                py="4px"
                                fontSize="14px"
                                cursor="pointer"
                                _hover={{
                                    backgroundColor: 'blue.500',
                                    color: 'white'
                                }}
                                onClick={() => handleClickRequest(request)}
                            >
                                <Flex width="20%" justifyContent="center">
                                    <Flex
                                        mr="8px"
                                        borderRadius="10px"
                                        width="6px"
                                        height="20px"
                                        backgroundColor={TextUtils.convertStatusColor(
                                            request.status
                                        )}
                                    ></Flex>
                                    <Text>
                                        {format(
                                            Date.parse(request.created_at),
                                            'MMM dd HH:mm:ss'
                                        )}
                                    </Text>
                                </Flex>

                                <Text width="20%" textAlign="center">
                                    {request.session?.account.company.name}
                                </Text>
                                <Text width="20%" textAlign="center">
                                    {request.session?.account.whatsapp}
                                </Text>
                                <Flex width="20%" justifyContent="center">
                                    <Badge
                                        color="white"
                                        backgroundColor="gray.500"
                                    >
                                        {request.method}
                                    </Badge>
                                </Flex>
                                <Text width="20%" textAlign="center">
                                    {String(request.endpoint).split('?')[0]}
                                </Text>
                                <Flex width="20%" justifyContent="center">
                                    <Badge
                                        color="white"
                                        backgroundColor={TextUtils.convertStatusColor(
                                            request.status
                                        )}
                                    >
                                        {request.status}
                                    </Badge>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
                <RequestView
                    request={request}
                    isOpen={isOpen}
                    onClose={setIsOpen}
                />
            </Flex>
        </Form>
    );
};

export default Dashboard;
