import React from 'react';
import { Routes as RoutesDOM, Route } from 'react-router-dom';

import LoginPage from '../../modules/auth/pages';
import CompanyEditPage from '../../modules/company/pages/EditPage';
import CompanyListPage from '../../modules/company/pages/ListPage';
import CompanyRegisterPage from '../../modules/company/pages/RegisterPage';
import LayoutPanel from '../components/Layout/LayoutPanel';
import Dashboard from '../pages/Dashboard';

const Routes: React.FC = () => (
    <RoutesDOM>
        <Route path="/" element={<LayoutPanel component={<Dashboard />} />} />

        <Route
            path="/login"
            element={<LayoutPanel component={<LoginPage />} />}
        />

        <Route
            path="/dashboard"
            element={<LayoutPanel component={<Dashboard />} />}
        />

        <Route
            path="/companies"
            element={<LayoutPanel component={<CompanyListPage />} />}
        />

        <Route
            path="/companies/create"
            element={<LayoutPanel component={<CompanyRegisterPage />} />}
        />

        <Route
            path="/companies/edit/:id"
            element={<LayoutPanel component={<CompanyEditPage />} />}
        />
    </RoutesDOM>
);

export default Routes;
