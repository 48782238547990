import { format, formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { motion, Variants } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { Badge, Flex, Stack, Text } from '@chakra-ui/react';

import TextUtils from '../../utils/TextUtils';

const RequestView: React.FC<any> = ({ request, isOpen, onClose }) => {
    const [canClose, setCanClose] = useState(true);

    const [formattedRequest, setFormattedRequest] = useState('');
    const [formattedResponse, setFormattedResponse] = useState('');

    const variants: Variants = {
        open: {
            opacity: 1
        },
        closed: {
            display: 'none',
            opacity: 0,
            width: '0%'
        }
    };

    useEffect(() => {
        if (request) {
            const requestJson = request.request;

            let body1 = '{<br>';
            Object.keys(requestJson).forEach(key => {
                body1 += `&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp<strong>${key}:</strong> ${requestJson[key]},<br>`;
            });
            body1 += '}';

            setFormattedRequest(body1);

            const responseJson = request.response;

            let body2 = '{<br>';
            Object.keys(responseJson).forEach(key => {
                body2 += `&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp<strong>${key}:</strong> ${responseJson[key]},<br>`;
            });
            body2 += '}';

            setFormattedResponse(body2);
        }
    }, [request]);

    return (
        <motion.div
            initial="closed"
            // eslint-disable-next-line no-constant-condition
            animate={isOpen ? 'open' : 'closed'}
            variants={variants}
            style={{
                zIndex: 1000,
                display: 'flex',
                position: 'absolute',
                top: '0',
                right: '0',
                width: '100%',
                height: '100vh'
            }}
            transition={{
                delay: 0.12
            }}
            onClick={() => {
                if (canClose) {
                    onClose();
                }
            }}
        >
            {request && (
                <Flex
                    ml="auto"
                    width="50%"
                    flexDirection="column"
                    backgroundColor="white"
                    boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);"
                    p="24px"
                    onMouseEnter={() => setCanClose(false)}
                    onMouseLeave={() => setCanClose(true)}
                >
                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Stack direction="row" spacing="8" alignItems="center">
                            <Badge
                                fontSize="20px"
                                px="8px"
                                backgroundColor={TextUtils.convertStatusColor(
                                    request.status
                                )}
                                color="white"
                            >
                                {request.status}
                            </Badge>

                            <Flex fontWeight="500" alignItems="center">
                                <Text
                                    fontSize="16px"
                                    px="8px"
                                    backgroundColor="gray.500"
                                    color="white"
                                >
                                    {request.method}
                                </Text>
                                <Text
                                    fontSize="14px"
                                    px="16px"
                                    mx="16px"
                                    backgroundColor="gray.100"
                                    wordBreak="break-all"
                                >{`https://api.sac.digital/v2/client${request.endpoint}`}</Text>
                            </Flex>
                        </Stack>

                        <Flex flexDirection="column">
                            <Text alignSelf="flex-end" fontSize="14px">
                                {format(
                                    Date.parse(request.created_at),
                                    'dd/MM HH:mm:ss'
                                )}
                            </Text>
                            <Text fontSize="14px">
                                {formatDistance(
                                    Date.parse(request.created_at),
                                    new Date(),
                                    {
                                        locale: ptBR,
                                        addSuffix: true
                                    }
                                )}
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex
                        mt="16px"
                        width="100%"
                        height="80px"
                        backgroundColor="gray.100"
                        border="2px solid"
                        borderColor="gray.900"
                        flexDirection="column"
                        px="8px"
                        py="4px"
                        fontSize="14px"
                        justifyContent="center"
                    >
                        <Flex width="100%" justifyContent="space-between">
                            <Flex width="48%" flexDirection="column">
                                <Text
                                    fontWeight="500"
                                    fontSize="16px"
                                    whiteSpace="nowrap"
                                >
                                    {`Seção ${request.session.account.company.name}`}
                                </Text>
                                <Text fontWeight="500" mr="8px">
                                    Data de expiração:
                                </Text>
                                <Text>
                                    {format(
                                        new Date(
                                            Number(request.session.expire_in)
                                        ),
                                        'dd/MM/yyyy HH:mm'
                                    )}
                                </Text>
                            </Flex>

                            <Flex
                                width="48%"
                                flexDirection="column"
                                alignItems="flex-end"
                            >
                                <Flex>
                                    <Text fontWeight="500" mr="8px">
                                        Nº. Requisições /notification [POST]:
                                    </Text>
                                    <Text>
                                        {request?.session?.data
                                            ?.notification_post || 0}
                                    </Text>
                                </Flex>
                                <Flex>
                                    <Text fontWeight="500" mr="8px">
                                        Nº. Requisições /protocol [POST]:
                                    </Text>
                                    <Text>
                                        {request?.session?.data
                                            ?.protocol_post || 0}
                                    </Text>
                                </Flex>
                                <Flex>
                                    <Text fontWeight="500" mr="8px">
                                        Nº. Requisições /notification/status
                                        [GET]:
                                    </Text>
                                    <Text>
                                        {request?.session?.data?.status_get ||
                                            0}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>

                    {request.childrens.length > 0 && (
                        <Flex width="100%" flexDirection="column">
                            <Text
                                mt="8px"
                                fontWeight="600"
                                fontSize="18px"
                                mb="8px"
                            >
                                {`Retentativas (${request.childrens.length}) `}
                            </Text>

                            <Flex width="100%" flexDirection="column">
                                {request.childrens.map(item => (
                                    <Flex
                                        key={item.id}
                                        width="100%"
                                        justifyContent="space-between"
                                        borderBottom="1px solid"
                                        borderColor="gray.100"
                                        alignItems="center"
                                        py="4px"
                                        fontSize="14px"
                                        cursor="pointer"
                                        _hover={{
                                            backgroundColor: 'blue.500',
                                            color: 'white'
                                        }}
                                    >
                                        <Flex
                                            width="20%"
                                            justifyContent="center"
                                        >
                                            <Flex
                                                mr="8px"
                                                borderRadius="10px"
                                                width="6px"
                                                height="20px"
                                                backgroundColor={TextUtils.convertStatusColor(
                                                    item.status
                                                )}
                                            ></Flex>
                                            <Text>
                                                {format(
                                                    Date.parse(item.created_at),
                                                    'MMM dd HH:mm:ss'
                                                )}
                                            </Text>
                                        </Flex>

                                        <Flex
                                            width="20%"
                                            justifyContent="center"
                                        >
                                            <Badge
                                                color="white"
                                                backgroundColor={TextUtils.convertStatusColor(
                                                    item.status
                                                )}
                                            >
                                                {item.status}
                                            </Badge>
                                        </Flex>
                                    </Flex>
                                ))}
                            </Flex>
                        </Flex>
                    )}

                    <Flex width="100%" flexDirection="column" mt="auto">
                        <Text
                            mt="8px"
                            fontWeight="500"
                            fontSize="24px"
                            mb="8px"
                        >
                            Request
                        </Text>

                        <Flex
                            width="100%"
                            flexDirection="column"
                            border="1px solid"
                            borderColor="gray.100"
                            px="16px"
                            py="8px"
                            borderRadius="4px"
                            fontWeight="100"
                        >
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: formattedRequest
                                }}
                            />
                        </Flex>
                    </Flex>

                    <Flex width="100%" flexDirection="column">
                        <Text
                            mt="80px"
                            fontWeight="500"
                            fontSize="24px"
                            mb="8px"
                        >
                            Response
                        </Text>

                        <Flex
                            width="100%"
                            flexDirection="column"
                            border="1px solid"
                            borderColor="gray.100"
                            px="16px"
                            py="8px"
                            borderRadius="4px"
                            fontWeight="200"
                        >
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: formattedResponse
                                }}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </motion.div>
    );
};

export default RequestView;
