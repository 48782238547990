import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit, FiPlus, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Icon,
    Stack,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import ModalDeleteConfirmation from '../../../../shared/components/ModalDeleteConfirmation';
import { HTTP_RESPONSE } from '../../../../shared/constants';

const CompanyListPage: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const navigate = useNavigate();

    const [companies, setCompanies] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState('');

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleSearch = useCallback((value: string) => {
        if (value) {
            apiBackend('')
                .get(`/companies?name=${value}`)
                .then(response => {
                    const { status, data } = response;

                    if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                        setCompanies(data);
                    }
                });
        }
    }, []);

    const handleDelete = useCallback(async (id: string) => {
        setSelectedItem(id);
        onOpen();
    }, []);

    const deleteFunction = useCallback(() => {
        apiBackend('')
            .delete(`/companies/${selectedItem}`)
            .then(response => {
                const { status } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setCompanies(oldState =>
                        oldState.filter(item => item.id !== selectedItem)
                    );
                }
            });
    }, [selectedItem]);

    const handleLoadData = useCallback(() => {
        apiBackend('')
            .get('/companies')
            .then(response => {
                const { status, data } = response;

                if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
                    setCompanies(data);
                }
            });
    }, []);

    useEffect(() => {
        handleLoadData();
    }, [handleLoadData]);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/companies">
                                Empresas
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    height="calc(100vh - 104px)"
                    backgroundColor="white"
                    flexDirection="row"
                    p="16px"
                    justifyContent="space-between"
                >
                    <Flex width="100%" flexDirection="column">
                        <Flex width="100%" justifyContent="space-between">
                            <Flex
                                width="144px"
                                justifyContent="center"
                                alignItems="center"
                                backgroundColor="blue.500"
                                borderRadius="2px"
                                py="4px"
                                cursor="pointer"
                                onClick={() => navigate('/companies/create')}
                            >
                                <Text
                                    fontSize="16px"
                                    color="white"
                                    fontWeight="500"
                                >
                                    Novo
                                </Text>

                                <Icon
                                    ml="16px"
                                    as={FiPlus}
                                    color="white"
                                    fontSize="20px"
                                />
                            </Flex>

                            <Flex width="50%">
                                <Input
                                    width="100%"
                                    name="search"
                                    mb="0px"
                                    placeholder="Pesquisar nome"
                                    onChange={e =>
                                        handleSearch(e.currentTarget.value)
                                    }
                                ></Input>
                            </Flex>
                        </Flex>

                        <Flex
                            mt="16px"
                            width="100%"
                            height="100%"
                            flexDirection="column"
                            borderRadius="8px"
                            border="1px solid"
                            borderColor="gray.100"
                            overflow="auto"
                        >
                            <Flex
                                width="100%"
                                fontWeight="500"
                                justifyContent="space-between"
                                borderBottom="1px solid"
                                borderColor="gray.100"
                                alignItems="center"
                                py="4px"
                            >
                                <Text width="80px" textAlign="center">
                                    #
                                </Text>

                                <Text width="20%" textAlign="center">
                                    Empresa
                                </Text>

                                <Text width="20%" textAlign="center">
                                    CNPJ
                                </Text>

                                <Text width="20%" textAlign="center">
                                    Nº Contas
                                </Text>

                                <Text width="20%" textAlign="center">
                                    Ação
                                </Text>
                            </Flex>

                            {companies.map((company, index) => (
                                <Flex
                                    key={company.id}
                                    width="100%"
                                    justifyContent="space-between"
                                    borderBottom="1px solid"
                                    borderColor="gray.100"
                                    alignItems="center"
                                    py="4px"
                                    fontSize="14px"
                                    _hover={{
                                        backgroundColor: 'blue.500',
                                        color: 'white'
                                    }}
                                >
                                    <Text width="80px" textAlign="center">
                                        {index + 1}
                                    </Text>
                                    <Text width="20%" textAlign="center">
                                        {company.name}
                                    </Text>
                                    <Text width="20%" textAlign="center">
                                        {company.document}
                                    </Text>
                                    <Text width="20%" textAlign="center">
                                        {company.accounts.length}
                                    </Text>

                                    <Flex width="20%" justifyContent="center">
                                        <Stack direction="row" spacing={2}>
                                            <Flex
                                                borderRadius="2px"
                                                backgroundColor="yellow.500"
                                                color="white"
                                                p="4px"
                                                title="Editar"
                                                cursor="pointer"
                                                onClick={() =>
                                                    navigate(
                                                        `/companies/edit/${company.id}`
                                                    )
                                                }
                                            >
                                                <Icon
                                                    as={FiEdit}
                                                    fontSize="18px"
                                                />
                                            </Flex>

                                            <Flex
                                                borderRadius="2px"
                                                backgroundColor="red.500"
                                                color="white"
                                                p="4px"
                                                onClick={() =>
                                                    handleDelete(company.id)
                                                }
                                            >
                                                <Icon
                                                    as={FiTrash2}
                                                    fontSize="18px"
                                                />
                                            </Flex>
                                        </Stack>
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
                <ModalDeleteConfirmation
                    deleteFunction={deleteFunction}
                    isOpen={isOpen}
                    onClose={onClose}
                />
            </Flex>
        </Form>
    );
};

export default CompanyListPage;
